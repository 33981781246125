@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap);
body {
  margin: 0;
  background-color: #fff;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** @format */

* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}

body::-webkit-scrollbar-track {
  background: #f7fbff !important;
}
body::-webkit-scrollbar-thumb {
  background: #c51559 !important;
  border-radius: 16px;
}
body::-webkit-scrollbar {
  width: 3px;
}
body {
  background: url("https://imgur.com/UYPpes4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  scroll-behavior: smooth;
}
/* // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                             Tabs
// ======================°°°°°°°°°°°°°°°°°°°°°°°========================= */

a {
  text-decoration: none !important;
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #ffffff;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #ffffff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  background-color: #c51559 !important;
  padding: 10px !important;
}

.offcanvas {
  -webkit-backdrop-filter: blur(10px) !important;
          backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}
.mybtn {
  background: #2a3427 !important;
  border: 0;
  color: #f8faf8 !important;
  font-size: 20px !important;
}
.Header-Section {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px !important;
}
.navbar-brand {
  font-family: "Cabin", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #ffffff;
}
.navbar-item {
  position: relative;
}
.navbar-item a {
  font-family: "Cabin", sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  transform: skewX(-18deg);
  max-width: 130px;
  font-size: 0.75rem;
  height: 40px;
  border-color: #dbdbdb;
  border-width: 1px;
  align-items: center;
  border-radius: 4px;
  box-shadow: none;

  display: inline-flex;
  justify-content: center;

  line-height: 1.5;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.6s ease-out !important;
  background: linear-gradient(to top, #eb689a, #c51559) !important;
  opacity: 1;
  z-index: 0;
  margin-left: 20px;
  justify-content: center;

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(127, 0, 255, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(63, 0, 252, 0.2) !important;
    opacity: 0.95;
  }
}
.Header-Section:hover {
  color: #ffffff;
}
.Header-Section li a {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-family: "Cabin", sans-serif;
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #ffffff !important;
  padding: 0.5rem 0.75rem;
}

.footer-section a {
  color: rgb(255, 255, 255);
  margin: 0px 4px;
  width: 30px;
  height: 30px;
  border-radius: 20px;
}
.footer-section a:hover {
  color: #eb008b;
}
.news {
  background: #c51559;
  padding-bottom: 0px;
  padding-top: 5px;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.img-box {
  min-height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Timer
// ======================°°°°°°°°°°°°°°°°°°°°°°°========================= */
.timer span {
  display: inline-block;
  padding-left: 15px;

  text-align: center;
}
@keyframes myAnim {
  0% {
    animation-timing-function: ease-out;
    transform: scale(1);
    transform-origin: center center;
  }

  10% {
    animation-timing-function: ease-in;
    transform: scale(0.91);
  }

  17% {
    animation-timing-function: ease-out;
    transform: scale(0.98);
  }

  33% {
    animation-timing-function: ease-in;
    transform: scale(0.87);
  }

  45% {
    animation-timing-function: ease-out;
    transform: scale(1.2);
  }
}

.timer {
  float: none;
  margin: 0 auto;
}

.timer small {
  font-weight: 300 !important;
  font-size: 11px !important;
}
.timer strong {
  font-size: 20px !important;
}
@keyframes myAnim {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
.h44 {
  padding-top: 10px !important;
  /* animation: myAnim 2s ease-in-out 1s infinite normal forwards !important; */
}
/* // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                             Tabs
// ======================°°°°°°°°°°°°°°°°°°°°°°°========================= */
.nav-pills {
  border-bottom: none;
  padding-right: 0 !important;
  width: 35% !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link img {
  opacity: 1 !important;
  background: transparent !important;
}
.nav-pills .nav-link {
  opacity: 0.5;
  cursor: pointer !important;
}
@media only screen and (max-width: 600px) {
  .nav-pills {
    width: 100% !important;
  }
}

